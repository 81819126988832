import React from "react"
import Layout from "../components/layout"
import Faq from "../components/faq"

class F extends React.Component {
  render() {
    return(
      <React.Fragment>
        <Layout>
          <Faq />
        </Layout>
      </React.Fragment>
    );
  }
}

export default F
