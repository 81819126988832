import React from "react"

class Faq extends React.Component {
  render() {
    return(
      <React.Fragment>
        <h3>FAQ</h3>
        <br />
        <div class="c-panel ">
          <div class="c-panel__header">
            <strong>I've created a vault... now how do I upload some files?</strong>
          </div>
          <div class="c-panel__content">
            <p>You'll need to submit a new upload request.</p>
            <br />
            <p>Go to "My vaults" then select the vault you wish to upload some files to. Under the "Files" section, you'll see the "Request new upload" button. Click this then complete the displayed form.</p>
            <br />
            <p>An admin will review your request and then upload the files for you (using an AWS service called <a href='https://aws.amazon.com/datasync/?whats-new-cards.sort-by=item.additionalFields.postDateTime&whats-new-cards.sort-order=desc'>DataSync</a>)</p>
          </div>
        </div>
    </React.Fragment>);
  }
}

export default Faq
